<template lang="pug">
.analytics__uom
    v-container
        v-card.ml-8(light)
            v-card-title.brand.white--text.py-2 Units Of Measure
            v-card-text
                 v-data-table(
                    :headers="headers"
                    :items="units"
                    :items-per-page="5"
                    dense
                )
                    template(v-slot:item.actions="{ item }")
                        v-icon(
                            small
                            color="#333"
                            @click="editItem(item)"
                        ) edit
                    template(v-slot:top)
                        v-toolbar(flat)
                            v-spacer
                            v-dialog(
                                v-model='dialog'
                                max-width='600px'
                                light
                            )
                                template(v-slot:activator="{on, attrs}")
                                    v-btn.brand.white--text(
                                        class="mb-2"
                                        v-bind="attrs"
                                        v-on="on"
                                    ) New Unit
                                v-card
                                    v-card-title.brand.white--text {{ formTitle }}
                                    v-card-text
                                        v-container
                                            v-form(ref="form" v-model="valid")
                                                v-row
                                                    v-col(cols="12" sm="9" md="2")
                                                        v-text-field(
                                                            v-model.trim="editedItem.uom_code"
                                                            :readonly="editedIndex > -1"
                                                            :rules="[rules.required, value => noDuplicate(value, editedItem.uom_code)]"
                                                            label="Code"
                                                            color="brand"
                                                            dense
                                                            :clearable="editedIndex === -1"
                                                            required
                                                        )
                                                    v-col(v-if="editedIndex > -1" cols="12" sm="3" md="9" class="d-flex align-center")
                                                        span read-only
                                                v-row
                                                    v-col(cols="12" sm="6" md="3")
                                                        v-select(
                                                            v-model="editedItem.type"
                                                            :items="uomTypes"
                                                            :rules="[rules.required]"
                                                            label="Type"
                                                            color="brand"
                                                        )
                                                v-row
                                                    v-col(cols="12" sm="6" md="8")
                                                        v-text-field(
                                                            v-model.trim="editedItem.name"
                                                            :rules="[rules.required]"
                                                            label="Name"
                                                            color="brand"
                                                            dense
                                                            clearable
                                                            required
                                                        )
                                                v-row(v-if="editedItem.uom_code === '%'")
                                                    v-col(cols="12")
                                                        span Note: '%' is the default unit of measure and must have an id of zero

                                    v-card-actions
                                        div(
                                            v-if="savedStatusMessage"
                                            :style="{ color: savedStatusMessage.includes('Saved') ? 'green' : 'red' }"
                                        )
                                            span {{ savedStatusMessage }}
                                        v-spacer
                                        v-btn.actions.brand.white--text(
                                            text
                                            :disabled="!valid"
                                            @click="save"
                                        ) Save
                                        v-btn(
                                            color="red"
                                            @click="close"
                                        ) Cancel
</template>

<style scoped lang="css">

.actions.v-btn--disabled.v-btn--text {
    background-color: #ddd !important;
}
.actions.v-btn--disabled > span {
    color: #777 !important;
    background-color: #ddd !important;
}

</style>


<script lang="js">

export default {
    meta: {
        role: 'analytics-uom'
    },
    emits: ['refresh', 'analyticsGetUnits', 'analyticsSaveUnit'],
    components: {
    },
    data () {
        return {
            headers: [
                { text: 'Code', value: 'uom_code' },
                { text: 'Name', value: 'name' },
                { text: 'Actions', value: 'actions', sortable: false }
            ],
            units: [],
            uomTypes: [
                'LINEAR', 'VOLUME', 'RATE'
            ],
            currentUomCode: '',
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            editedItem: {},
            defaultItem: {
                uom_code: '',
                name: '',
                type:'NONE'
            },
            dialogTitle: '',
            valid: false,
            rules: {
                required: value => !!value || 'Required.',
            },
            savedStatusMessage: '',
        };
    },
    methods:{
        initialize () {
            this.getUnits();
        },
        getUnits () {
            this.socketEmit('analyticsGetUnits', null, ({units, error}) => {
                if(units) {
                    this.$set(this, 'units', units);
                } else {
                    this.$set(this, 'units', []);
                    console.error('Error fetching units:', error);
                }
            });
        },
        close () {
            this.dialog = false;

            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                this.valid = false;
            });
        },

        delete () {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        editItem (item) {
            this.editedIndex = this.units.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },

        save () {
            if (this.editedIndex > -1) {
                this.$set(this.units, this.editedIndex, {...this.editedItem});
            } else {
                this.units.push({...this.editedItem});
            }
            this.socketEmit('analyticsSaveUnit', this.editedItem, (sts) => {
                if(sts.error){
                    this.savedStatusMessage = sts.error;
                } else {
                    this.savedStatusMessage = 'Saved';
                    setTimeout(() => {
                        this.savedStatusMessage = '';
                    }, 2000);
                    this.close();
                    this.$emit('refresh');
                }

            });
        },

        noDuplicate (value, currentUomCode) {
            const exists = this.units.find(u => u.uom_code === value && u.uom_code !== currentUomCode);
            return !exists || 'Uom already exists';
        },

    },
    watch: {
        dialog (val) {
            val || this.close();
        },

        dialogDelete (val) {
            val || this.delete();
        },
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Unit' : 'Edit Unit';
        },
    },
    mounted () {
        this.initialize();
    },
    created () {
        this.$store.dispatch('connectSocket');
    },
};
</script>
